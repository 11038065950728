@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}


@mixin vp--1919 {
  @media (max-width: $vp--1920 - 1) {
    @content;
  }
}

@mixin vp--1459 {
  @media (max-width: 1459px) {
    @content;
  }
}

@mixin vp--1439 {
  @media (max-width: $vp--1440 - 1) {
    @content;
  }
}

@mixin vp--1350 {
  @media (max-width: 1350px) {
    @content;
  }
}

@mixin vp--1279 {
  @media (max-width: $vp--1280 - 1) {
    @content;
  }
}

@mixin vp--1240 {
  @media (max-width: 1240px) {
    @content;
  }
}

@mixin vp--1023 {
  @media (max-width: $vp--1024 - 1) {
    @content;
  }
}

@mixin vp--767 {
  @media (max-width: $vp--768 - 1) {
    @content;
  }
}

@mixin vp--500 {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin vp--579 {
  @media (max-width: 579px) {
    @content;
  }
}

@mixin vp--440 {
  @media (max-width: 440px) {
    @content;
  }
}

@mixin vp--374 {
  @media (max-width: $vp--375 - 1) {
    @content;
  }
}

@mixin vp--359 {
  @media (max-width: 359px) {
    @content;
  }
}


@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}


@mixin hover {
  @media (hover: hover), screen and (min-width: 0\0) {
    @content;
  }
}


@mixin expand-interactive-area($width, $height: $width) {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: $width;
    height: $height;
    transform: translate(-50%, -50%);
  }
}


@mixin aspect-ratio($ratio) {
  &::after {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: $ratio * 100%;
  }
}

@mixin font($font_name, $file_name, $weight, $style) {
  @font-face {
    font-family: $font_name;
    font-style: $style;
    font-weight: $weight;
    font-display: swap;
    src:
      url("../fonts/#{$file_name}.woff2") format("woff2"),
      url("../fonts/#{$file_name}.woff") format("woff");
  }
}
