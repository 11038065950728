.title {
  margin-top: 0;
  margin-bottom: 0;
  font-family: $font-family--open-sans;
  font-weight: 400;

  &--h1 {
    font-size: 64px;
    line-height: 1.12;

    @include vp--1023 {
      font-size: 48px;
    }

    @include vp--767 {
      font-weight: 700;
      font-size: 40px;
      line-height: 1.1;
    }
  }

  &--h2 {
    font-size: 40px;
    line-height: 1.17;

    @include vp--1023 {
      font-size: 40px;
    }

    @include vp--767 {
      font-size: 32px;
      line-height: 1.09;
    }

    @include vp--359 {
      font-size: 28px;
    }
  }

  &--h3 {
    font-weight: 400;
    font-size: 32px;
    line-height: 1.17;

    @include vp--1023 {
      font-size: 28px;
    }

    @include vp--767 {
      font-size: 24px;
      line-height: 1.17;
    }
  }

  &--gold {
    color: $color-gold-text;
  }

  &--gradient {
    background-image: $brown-to-green;
    background-clip: text;
    color: transparent;
  }
}
