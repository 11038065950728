.close-button {
  $padding: 8px;

  position: relative;

  width: 60px;
  height: 60px;
  padding: $padding;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 10px 0 rgba($color-default-black, 0.2);

  background-color: rgba($color-default-white, 0.5);
}

.close-button__semi-cross {
  $padding: 8px;

  display: block;

  position: absolute;
  z-index: 1;

  width: calc(100% - $padding * 2);
  height: 12px;
  top: 50%;
  left: $padding;

  border-radius: 6px;
  background-color: $color-snow-drift;

  &--left {
    transform: translateY(-50%) rotate(-45deg);
  }

  &--right {
    transform: translateY(-50%) rotate(45deg);
  }
}

.close-button:hover {
  box-shadow: 0 0 20px 0 rgba($color-dark-cyan, 0.5);
  cursor: pointer;

  .close-button__semi-cross {
    background-color: rgba($color-dark-cyan, 0.9);
  }
}
