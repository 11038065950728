.end-game-modal__title {
  background-image: $brown-to-green;
  background-clip: text;
  color: transparent;
}

.end-game-modal__content-result {
  font-family: $font-family--roboto;
  font-size: 25px;

  span {
    font-family: $font-family--ledger;
    font-size: 25px;
    color: $color-tints-of-malachite;
  }
}
