
.button {
  width: 150px;
  padding: 15px 16px;

  font-family: $font-family--roboto;
  font-size: 18px;
  color: $color-default-white;
  line-height: 1.2;

  text-align: center;

  background-image: radial-gradient(100% 100% at 100% 0, $festival 0, $color-error-radio 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;

  cursor: pointer;
  user-select: none;

  transition: box-shadow 0.15s, transform 0.15s;

  will-change: box-shadow, transform;

  &:focus-visible:not(:disabled) {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }

  &:hover:not(:disabled) {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  &:active:not(:disabled) {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
}

.button:disabled {
  background-image: radial-gradient(100% 100% at 100% 0, $color-light-grey 0, $color-nobel 100%);
}
