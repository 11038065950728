.puzzle {
  display: flex;
  position: relative;

  background-color: $color-whisper;

  overflow-y: hidden;
}

.puzzle__message {
  display: flex;
  justify-content: center;

  position: absolute;
  z-index: 1;
  left: 50%;

  width: 50%;
  padding: 20px 30px;

  font-family: $font-family--roboto;
  font-size: 40px;
  color: $color-default-white;
  text-align: center;

  text-shadow: 2px 2px 10px $color-default-black;

  transform: translate(-50%, 0);

  pointer-events: none;

  span {
    animation: go-bottom-top linear 2s infinite alternate;
  }

  b {
    color: $color-error-radio;

    text-shadow: 1px 1px 5px $color-default-black;
  }

  &--hidden {
    display: none;
  }

  @include vp--1023 {
    font-size: 30px;
  }

  @include vp--767 {
    width: 100%;
    font-size: 30px;
  }
}

.puzzle__box-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-grow: 1;

  padding-top: 50px;
  padding-bottom: 15px;

  overflow-y: hidden;
}

.puzzle__box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);

  position: relative;
  background-color: transparent;

  border: 1px solid $color-default-white;

  box-shadow: 5px 5px 15px 0 rgba($color-default-black, 0.5);
}

.box__cell {
  position: relative;
}

.box__item {
  position: absolute;
  width: 100%;
  height: 100%;

  cursor: url("../img/cursors/move30.png"), pointer;

  &.draggable-mirror {
    opacity: 0.5;

    z-index: 10;
  }

  &.draggable-source--is-dragging {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      background: url("../img/bg/bg-line.png");
    }
  }
}

@keyframes go-bottom-top {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100px);
  }
}
