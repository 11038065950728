.upload-file-wrapper {
  $defaultColor: $color-dark-cyan;
  $height: 30px;
  $buttonWidth: 80px;

  position: relative;
  width: 300px;
  height: $height;

  font-size: 14px;

  &::before {
    content: attr(data-text);

    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    width: calc(100% - $buttonWidth);
    height: 100%;
    padding: 5px 15px;

    color: $color-nobel;

    background-color: $color-default-white;
    border-radius: 5px 0 0 5px;
  }

  &::after {
    content: "Upload";

    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;

    height: $color-default-white;
    width: $buttonWidth;
    padding: 0 5px;

    font-size: 16px;
    line-height: $height;
    font-weight: 700;
    color: $color-default-white;


    background: $defaultColor;
    border-radius: 0 5px 5px 0;

    text-transform: uppercase;
    pointer-events: none;
  }

  &:hover {
    &::after {
      background-color: darken($defaultColor, 10%);
    }
  }
}

.upload-file {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;

  width: 100%;
  height: 100%;

  opacity: 0;

  cursor: pointer;
}
