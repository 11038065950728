.menu-button {
  display: block;

  position: relative;

  width: 300px;
  padding: 5px 30px 5px 15px;

  font-size: 14px;
  color: $color-nobel;

  text-align: left;

  background-color: $color-default-white;
  border: none;
  border-radius: 5px;

  cursor: pointer;

  &::after {
    display: block;

    content: "";

    position: absolute;
    z-index: 1;
    top: 50%;
    right: 5px;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 10px 0 10px;
    border-color: $color-dark-cyan transparent transparent transparent;

    transform: translateY(-50%);

    pointer-events: none;

    transition: transform 1s ease;
  }

  &:hover {
    &::after {
      border-color: darken($color-dark-cyan, 10%) transparent transparent transparent;
    }
  }

  &::before {
    display: flex;
    align-items: center;
    justify-content: center;

    content: "or";

    position: absolute;
    z-index: 1;
    top: -23px;
    left: 0;

    width: 100%;
    height: 20px;

    font-weight: 700;
    color: $color-default-black;
    font-size: 14px;
    text-align: center;

    text-transform: uppercase;
  }
}

.menu-button--opened {
  &::after {
    transform: translateY(-60%) rotate(-180deg);
  }
}
