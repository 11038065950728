.link {
  display: inline;
  width: fit-content;

  color: inherit;
  transition: color $trans--300;

  cursor: pointer;

  text-decoration-thickness: 1px;
  text-decoration-line: none;
  text-decoration-color: currentColor;
  text-underline-offset: 0.2em;
  text-decoration-skip: none;

  @include hover {
    &:hover {
      text-decoration-line: underline;
    }

    &:hover svg {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }

  &:focus:hover {
    text-decoration: none;
  }

  &:active {
    opacity: $opacity--active;
    outline: none;
  }

  &:focus:active {
    box-shadow: none;
  }

  &--icon {
    display: flex;
    align-items: center;

    @include hover {
      &:hover {
        text-decoration-line: none;
        color: rgba($color-default-white, 0.3);
      }
    }

    &:active,
    &:focus {
      box-shadow: 0 0 0 1px rgba($color-tropical-rain-forest, 0.3);
    }
  }

  & svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  &--color-green {
    color: $color-tropical-rain-forest;
    text-decoration-color: rgba($color-tropical-rain-forest, 0.3);

    @include hover {
      &:hover {
        text-decoration-color: $color-tropical-rain-forest;
      }
    }
  }

  &--color-white {
    color: $color-default-white;
    text-decoration-color: rgba($color-default-white, 0.3);

    @include hover {
      &:hover {
        text-decoration-color: $color-default-white;
      }
    }
  }

  &--color-gold {
    color: $color-gold-text;
    text-decoration-color: rgba($color-gold-text, 0.3);

    @include hover {
      &:hover {
        text-decoration-color: $color-gold-text;
      }
    }
  }

  &--color-black {
    color: $color-default-black;
    text-decoration-color: rgba($color-default-black, 0.3);

    @include hover {
      &:hover {
        text-decoration-color: $color-default-black;
      }
    }
  }

  &--color-gray {
    color: rgba($color-dark-gray, 0.3);
    text-decoration-color: rgba($color-dark-gray, 0.3);

    @include hover {
      &:hover {
        text-decoration-color: $color-dark-gray;
      }
    }
  }


  &--dashed {
    text-decoration-line: underline;
    text-decoration-style: dashed;
  }

  &--solid {
    text-decoration-line: underline;
    text-decoration-style: solid;
  }

  &--block {
    display: block;
  }

  &--nowrap {
    white-space: nowrap;
  }

  &--gold {
    color: $color-gold-text;
    text-decoration-color: $color-gold-text;
    opacity: 0.3;

    @include hover {
      &:hover {
        text-decoration-color: transparent;
      }
    }

    &:active,
    &:focus {
      pointer-events: none;
      text-decoration-color: transparent;
    }

    &:focus {
      outline: none;
    }
  }
}

