.image {
  position: relative;

  margin: 0;

  @include vp--767 {
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }

  &--mobile-full {
    @include vp--767 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.image img {
  width: 100%;
  height: auto;


  border-radius: 8px;

  object-fit: cover;
}

.image--no-radius img {
  border-radius: 0;
}

.image--mobile-no-radius img {
  @include vp--767 {
    border-radius: 0;
  }
}

.image--mobile-short img {
  @include vp--374 {
    height: 231px;
  }
}

.image--mobile-medium img {
  @include vp--374 {
    height: 320px;
  }
}

.image__caption {
  margin-top: 8px;

  font-weight: 600;
  font-size: 14px;
  line-height: 1.43;

  @include vp--767 {
    margin-top: 8px;

    font-size: 14px;
    line-height: 1.14;
  }
}

