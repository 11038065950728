.grid-cards {
  &__list {
    @include list-reset;

    display: grid;
    gap: 40px;

    @include vp--767 {
      row-gap: 40px;
    }
  }

  &--row-gap-large &__list {
    row-gap: 52px;

    @include vp--767 {
      row-gap: 44px;
    }
  }

  &--2-columns &__list {
    grid-template-columns: repeat(2, calc((100% - 40px) / 2));

    @include vp--767 {
      grid-template-columns: 100%;
    }
  }

  &--3-columns &__list {
    grid-template-columns: repeat(3, calc((100% - 2 * 40px) / 3));

    @include vp--1023 {
      grid-template-columns: repeat(2, calc((100% - 40px) / 2));
    }

    @include vp--767 {
      grid-template-columns: 100%;
    }
  }

  &--auto-columns &__list {
    grid-template-columns: repeat(auto-fill, minmax(min-content, 250px));
    justify-content: start;

    @include vp--1023 {
      row-gap: 20px;
      column-gap: 20px;
    }

    @include vp--767 {
      grid-template-columns: repeat(auto-fill, minmax(min-content, 200px));
      row-gap: 20px;
      column-gap: 20px;
    }
  }

  &--3-columns-mobile &__list {
    grid-template-columns: repeat(3, 1fr);
    justify-content: start;

    @include vp--1023 {
      row-gap: 20px;
      column-gap: 20px;
    }

    @include vp--767 {
      grid-template-columns: repeat(3, 1fr);

      row-gap: 20px;
      column-gap: 20px;
    }
  }

  &--4-columns &__list {
    grid-template-columns: repeat(4, calc((100% - 3 * 40px) / 4));

    @include vp--1023 {
      gap: 24px 20px;
      grid-template-columns: repeat(3, 1fr);
    }

    @include vp--767 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
    &--flex {
      display: flex;
    }

    &--full {
      grid-column: 1 / -1;
    }
  }
}
