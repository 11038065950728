.container {
  max-width: $container-width + $desktop-padding * 2;
  width: 100%;
  margin: 0 auto;
  padding-left: $desktop-padding;
  padding-right: $desktop-padding;

  overflow: visible;

  @include vp--1023 {
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }

  @include vp--359 {
    padding-left: 10px;
    padding-right: 10px;
  }


  &--no-tablet-padding {
    @include vp--1023 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--no-mobile-padding {
    @include vp--767 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--mobile-only {
    max-width: 100%;

    padding-left: 0;
    padding-right: 0;

    @include vp--1439 {
      padding-left: 0;
      padding-right: 0;
    }

    @include vp--1023 {
      padding-left: 0;
      padding-right: 0;
    }

    @include vp--767 {
      padding-left: $mobile-padding;
      padding-right: $mobile-padding;
    }
  }
}

