.header {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;

  padding-top: 20px;
  padding-bottom: 20px;

  background: $header-bg;
  border-radius: 6px 6px 0 0;

  transition: transform 0.3s linear;

  &--hidden {
    position: absolute;
    transform: translateY(-100%);
  }

  &--paused {
    position: relative;

    transform: translateY(0);
  }

  @include vp--1350 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.header__logo {
  width: 70px;
  height: 70px;

  margin-right: 16px;

  mask-image: url("../img/svg/logo-mask.svg");
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-size: 100% 100%;

  @include vp--1350 {
    width: 50px;
    height: 50px;
  }

  @include vp--1023 {
    width: 70px;
    height: 70px;
  }

  @include vp--767 {
    display: none;
  }
}

.header__logo-img {
  position: relative;

  width: 100%;
  height: 100%;

  border-radius: 8px;

  background-image: url("../img/bg/nature.jpg");
  background-size: cover;

  animation: logo-bg 8s ease infinite alternate 2s;
}

.header__title {
  font-weight: 700;
  font-size: 35px;

  text-transform: uppercase;

  background: $logo-bg;
  background-clip: text;
  color: transparent;

  text-shadow: 2px 2px 3px rgba($color-default-black, 0.5);

  @include vp--1350 {
    font-size: 30px;
  }

  @include vp--1023 {
    display: none;
  }
}

.header__menu {
  display: flex;
  margin-left: 100px;

  @include vp--1350 {
    margin-left: 50px;
  }

  @include vp--767 {
    margin-left: 0;
  }

  @include vp--579 {
    justify-content: space-between;

    width: 100%;
  }
}

.header__upload-file {
  @include vp--1350 {
    width: 250px;
  }

  @include vp--579 {
    width: 100px;

    &::before {
      content: "";
    }
  }
}

.header__menu-button {
  margin-top: 24px;

  @include vp--1350 {
    width: 250px;
  }

  @include vp--579 {
    width: 100px;
  }
}

.header__image-preview {
  margin-left: 30px;

  @include vp--579 {
    margin-left: 15px;
  }

  @include vp--359 {
    margin-left: 10px;
  }
}

.header__menu-right {
  margin-left: 30px;

  @include vp--579 {
    width: 100px;
    margin-left: 15px;
  }

  @include vp--359 {
    margin-left: 10px;
  }
}

.header__select-piece-count {
  @include vp--579 {
    width: 100%;
  }
}

.header__start-button {
  margin-top: 10px;

  @include vp--579 {
    width: 100%;
  }
}

.header__trigger {
  display: none;

  position: absolute;
  left: 50%;
  bottom: 0;

  width: 100px;
  height: 30px;

  transform: translate(-50%, 100%);

  border-radius: 0 0 10px 10px;
  background-color: $color-dark-cyan;
  background-image: url("../img/bg/arrows20-down.png");
  background-repeat: no-repeat;
  background-size: 25px 25px;
  background-position: center center;

  border: none;

  box-shadow: 2px 2px 2px 0 rgba($color-default-black, 0.5);

  cursor: pointer;

  .header--hidden & {
    display: block;
  }

  .header--paused & {
    display: block;

    background-image: url("../img/bg/arrows20-up.png");
  }

  &:hover {
    box-shadow: 2px 2px 2px 0 rgba($color-default-black, 0.3),
      6px 6px 6px 0 rgba($color-tints-of-malachite, 0.7);
  }
}

@keyframes logo-bg {
  0% {
    background-image: url("../img/bg/nature.jpg");
  }

  50% {
    background-image: url("../img/bg/swan.jpg");
  }

  100% {
    background-image: url("../img/bg/tiger.jpg");
  }
}
