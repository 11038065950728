.custom-select {
  width: 150px;
}


//******************** TEMPLATE green-white **************************************

.custom-select--green-white {
  .choices[data-type*="select-one"] {
    // --- СТРЕЛКА ---
    &::after {
      display: block;

      content: "";

      height: 0;
      width: 0;
      margin-top: 0;

      border-style: solid;
      border-width: 15px 10px 0;
      border-color: $color-dark-cyan transparent transparent;

      pointer-events: none;
      transform: translateY(-50%);

      transition: transform 0.3s ease;
    }

    &:hover::after {
      border-color: darken($color-dark-cyan, 10%) transparent transparent;
    }
  }

  .choices[data-type*="select-one"].is-open::after {
    margin-top: 0;

    border-color: darken($color-dark-cyan, 10%) transparent transparent;

    transform: translateY(-60%) rotate(-180deg);
  }

  // --- БЛОК С ВЫБРАННЫМ ЗНАЧЕНИЕМ ---
  .choices__inner {
    height: auto;
    min-height: auto;
    padding: 5px 30px 5px 15px;

    font-size: 14px;
    line-height: 1.2;

    border: none;
    border-radius: 6px;

    background-color: $color-default-white;
  }

  .choices.is-open {
    // блок с выбрвнным знвчением, когда селкт открыт
    .choices__inner {
      padding: 5px 30px 5px 15px;
      border: none;
      border-radius: 6px 6px 0 0;
    }
  }

  .choices.is-open.is-flipped {
    // блок с выбрвнным знвчением, когда селкт открыт
    .choices__inner {
      padding: 5px 30px 5px 15px;
      border: none;
      border-radius: 0 0 6px 6px;
    }
  }

  //внутренний блок с выбрвнным знвчением
  .choices__list--single {
    padding: 0;
  }


  // ---- ВЫПАДАЮЩИЙ СПИСОК ----- (обертка)
  .choices__list--dropdown,
  .choices__list[aria-expanded] {
    background-color: $color-celadon;

    border: none;
    border-radius: 0 0 6px 6px;

    .choices__list {
      max-height: 150px;
    }

    .choices__item {
      padding: 6px;
      padding-left: 20px;

      color: $color-default-black;
    }

    .choices__item--selectable.is-highlighted {
      background-color: darken($color-celadon, 10%);
      background-image: linear-gradient(to bottom left, $color-dark-cyan 0%, darken($color-celadon, 10%) 100%);
    }
  }

  .choices.is-open.is-flipped .choices__list--dropdown,
  .choices.is-open.is-flipped .choices__list[aria-expanded] {
    border-radius: 6px 6px 0 0;
  }
}
