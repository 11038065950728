.image-preview {
  position: relative;

  width: 80px;
  height: 80px;

  border-radius: 6px;
  background-color: rgba($color-default-white, 1);
}

.image-preview img {
  $padding: 6px;

  position: absolute;
  left: $padding;
  top: $padding;
  z-index: 1;

  width: calc(100% - $padding * 2);
  height: calc(100% - $padding * 2);

  object-fit: cover;

  border-radius: 6px;
}

.image-preview__blank {
  $padding: 6px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);

  position: absolute;
  left: $padding;
  top: $padding;
  z-index: 2;

  width: calc(100% - $padding * 2);
  height: calc(100% - $padding * 2);

  border-radius: 6px;

  span {
    font-family: $font-family--ledger;
    font-size: 14px;
    color: $color-default-white;

    text-align: center;

    background-color: $color-dark-gray;

    opacity: 1;

    &:nth-child(1) {
      transition: opacity 0.1s linear 0.8s;
    }

    &:nth-child(2) {
      transition: opacity 0.1s linear 2.2s;
    }

    &:nth-child(3) {
      transition: opacity 0.1s linear 1.4s;
    }

    &:nth-child(4) {
      transition: opacity 0.1s linear 0.5s;
    }

    &:nth-child(5) {
      transition: opacity 0.1s linear 1.9s;
    }

    &:nth-child(6) {
      transition: opacity 0.1s linear 1.6s;
    }

    &:nth-child(7) {
      transition: opacity 0.1s linear 0.2s;
    }

    &:nth-child(8) {
      transition: opacity 0.1s linear 2.5s;
    }

    &:nth-child(9) {
      transition: opacity 0.1s linear 2.3s;
    }

    &:nth-child(10) {
      transition: opacity 0.1s linear 0.4s;
    }

    &:nth-child(11) {
      transition: opacity 0.1s linear 0.6s;
    }

    &:nth-child(12) {
      transition: opacity 0.1s linear 2.4s;
    }

    &:nth-child(13) {
      transition: opacity 0.1s linear 1s;
    }

    &:nth-child(14) {
      transition: opacity 0.1s linear 1.5;
    }

    &:nth-child(15) {
      transition: opacity 0.1s linear 1.2s;
    }

    &:nth-child(16) {
      transition: opacity 0.1s linear 1.1s;
    }

    &:nth-child(17) {
      transition: opacity 0.1s linear 2s;
    }

    &:nth-child(18) {
      transition: opacity 0.1s linear 1.8s;
    }

    &:nth-child(19) {
      transition: opacity 0.1s linear 0.1s;
    }

    &:nth-child(20) {
      transition: opacity 0.1s linear 2.1s;
    }

    &:nth-child(21) {
      transition: opacity 0.1s linear 0.3s;
    }

    &:nth-child(22) {
      transition: opacity 0.1s linear 1.3s;
    }

    &:nth-child(23) {
      transition: opacity 0.1s linear 0.7s;
    }

    &:nth-child(24) {
      transition: opacity 0.1s linear 1.7s;
    }

    &:nth-child(25) {
      transition: opacity 0.1s linear 0.9s;
    }
  }

  &--invisible {
    span {
      opacity: 0;
    }
  }
}
