.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 30px 0;

  overflow-y: auto;
  visibility: hidden;

  background-color: rgba(0, 0, 0, 0.35);
  -webkit-overflow-scrolling: touch;

  &--active {
    visibility: visible;
  }
}

.modal__inner {
  display: flex;
  flex-direction: column;

  width: 500px;
  max-width: 100%;
  margin: auto 0;

  overflow-y: auto;

  background-color: $color-default-white;
  border: 1px solid $color-gainsboro;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
}

.modal__inner h2 {
  width: 100%;
  padding: 1rem;
  margin: 0;
  border-bottom: 1px solid #ccc;
}

.modal__inner-content {
  padding: 1rem;
}

.modal__inner-footer {
  display: flex;

  padding: 0.5rem 1rem;
  border-top: 1px solid #ccc;
  background: #eee;
}

.modal__inner-button {
  padding: 0.5rem 1rem;

  font-size: 0.8rem;
  line-height: 1;

  border: 0;
  border-radius: 5px;
  background: #78f89f;

  cursor: pointer;
}

