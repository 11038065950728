.popup-menu {
  display: flex;
  flex-grow: 1;

  width: 100%;

  overflow: hidden;
}

.popup-menu__wrapper {
  position: relative;
  z-index: 0;

  height: 100%;
  padding-top: 40px;
  padding-bottom: 25px;
  padding-right: 15px;

  background-color: $popup-menu-bg;
  border-bottom: 5px solid rgba($color-dark-cyan, 0.1);
  transition: transform 0.5s linear;
  transform: translateY(-100%);
}

.popup-menu__inner {
  height: 100%;

  // overflow: hidden;

  .simplebar-scrollbar::before {
    background-image: $popup-scroll;
  }
}

.popup-menu__title {
  margin-bottom: 15px;
}

.popup-menu__list {
  width: 90%;

  padding-bottom: 25px;

  @include vp--1023 {
    width: 80%;
  }
}

.popup-menu__column-list {
  @include list-reset;
}

.popup-menu__column-item {
  display: flex;

  padding-right: 10px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  background-color: rgba($color-default-white, 0.5);
  border-radius: 5px;
}

.popup-menu__column-item img {
  width: 50px;
  height: 50px;

  margin-right: 15px;

  border-radius: 5px;

  object-fit: cover;
}

.popup-menu__close-button {
  position: absolute;
  top: 30px;
  right: 60px;
  z-index: 1;

  @include vp--767 {
    right: 45px;
  }

  @include vp--359 {
    right: 35px;
  }
}

.popup-menu--opened {
  .popup-menu__wrapper {
    transform: translateY(0);
  }
}
